import React, { useState, useRef } from "react"
import {
  Box,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  VStack,
  Radio,
  RadioGroup,
  Flex
} from "@chakra-ui/react"

import { useForm } from "react-hook-form"

const CodeEnforcementForm = React.forwardRef((props, ref) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const formRef = useRef(null);

  const watchData = watch();
 
  const sendFormData = async (event) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
      console.log("something went wrong with the ref");
    }

    const formData = new FormData(formRef.current);

    // convert signature to blob
    //const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");

    // append blob to formData
    //formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");


    // Use console.log() to see the data that is being sent to the server
    //console.log(Array.from(formData.entries()));

      fetch('/.netlify/functions/send-code-violation', {
           method: 'POST',
           body: formData,
       })
       // Convetring the response to JSON breaks status code
       .then(res => {
        console.log(res)
           if (res.status === 200) {
                 setSucceeded(true)
                 setProcessing(false)
                 reset()
                 //scrollTop()
             } else {
                 setSucceeded(false)
                 console.log("network error")
                 alert("Network error. Please try again later.")
             }
    }
 )
  };

  const SuccessMessage = () => (
    <Box my="20">
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Message submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
    Thanks for your submission. We'll be in touch soon.
  </AlertDescription>
    </Alert>
  </Box>
  )
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Box w="100%" mb="24" id="code-enforcement-form" ref={ref}>
      { succeeded ? <SuccessMessage /> : (
        <>
        <Heading as="h3" textTransform="uppercase" mb={4}>Report A Code Violation</Heading>
        <form onSubmit={handleSubmit(sendFormData)} ref={formRef}>

        <Box w="100%" mb="4">
              <FormControl id="violationAddress">
                <FormLabel>Location of a potential violation<Text color="#bf1650" as="span">*</Text></FormLabel>
                <Input type="text"
                    {...register("address", {required: true} )}
                    placeholder="123 Main St, Driggs, ID 83455"
                />
                {errors.violationAddress && <Text color="#bf1650">Please enter the violation address.</Text>}
              </FormControl>
            </Box>
            
            <Flex w="100%" flexWrap="wrap">
              <Box w={{base: '100%', lg: '33%'}} pr={{base: 0, lg: 2}} mb={4}>
                <FormControl id="propertyOwnerName">
                  <FormLabel>Property Owner Name (if known)</FormLabel>
                  <Input type="text"
                      {...register("propertyOwnerName")}
                  />
                  {errors.propertyOwnerName && <Text color="#bf1650">Please enter a name.</Text>}
                </FormControl>
              </Box>
  
              <Box w={{base: '100%', lg: '33%'}} pr={{base: 0, lg: 2}} mb={4}>
                <FormControl id="propertyOwnerPhone">
                  <FormLabel>Propery Owner Phone Number</FormLabel>
                  <Input type="tel"
                      {...register("propertyOwnerPhone")}
                  />
                </FormControl>
              </Box>
              <Box w={{base: '100%', lg: '33%'}} mb={4}>
                <FormControl id="propertyOwnerEmail">
                  <FormLabel>Property Owner Email address</FormLabel>
                  <Input type="propertyOwnerEmail" 
                     {...register("propertyOwnerEmail", 
                      {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                       }
                    }
                    )}
                     />
                     {errors.propertyOwnerEmail && <Text color="#bf1650">Please enter a valid email address.</Text>}
                </FormControl>
              </Box>
            </Flex>

            <Box mb="12">
            <FormControl id="message" mb={4}>
          <FormLabel>Description of a potential violation<Text color="#bf1650" as="span">*</Text></FormLabel>
            <Textarea placeholder=""
                {...register("description", {required: true} )}
                />
                {errors.reasons && <Text color="#bf1650">Please enter a description.</Text>}
            </FormControl>
            </Box>
            
            <Flex w="100%" flexWrap="wrap">
              <Box w={{base: '100%', lg: '33%'}} pr={{base: 0, lg: 2}} mb={4}>
                <FormControl id="name">
                  <FormLabel>Your Name<Text color="#bf1650" as="span">*</Text></FormLabel>
                  <Input type="text"
                      {...register("name", {required: true} )}
                  />
                  {errors.name && <Text color="#bf1650">Please enter your name.</Text>}
                </FormControl>
              </Box>
  
              <Box w={{base: '100%', lg: '33%'}} pr={{base: 0, lg: 2}} mb={4}>
                <FormControl id="phone">
                  <FormLabel>Your Phone Number<Text color="#bf1650" as="span">*</Text></FormLabel>
                  <Input type="tel"
                      {...register("phone", {required: true} )}
                  />
                </FormControl>
              </Box>
              <Box w={{base: '100%', lg: '33%'}} mb={4}>
                <FormControl id="email">
                  <FormLabel>Your Email address<Text color="#bf1650" as="span">*</Text></FormLabel>
                  <Input type="email" 
                     {...register("email", 
                      {
                        required: true, 
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                       }
                    }
                    )}
                     />
                     {errors.email && <Text color="#bf1650">Please enter a valid email address.</Text>}
                </FormControl>
              </Box>
            </Flex>
            <Box>
                      <input
                        type="hidden"
                        value={new Date()}
                        name="timestamp"
                        {...register("timestamp", { required: true })}
                      />
                    </Box>
          <Box mt="4">
          <Button 
            disabled={!isValid || processing}
            type="submit"
            mt={4}
            isLoading={processing}
            loadingText='Submitting'
            >
              Submit
          </Button>
          </Box>
        </form>
        </>
      )
}
      </Box>
    </>
  )
})

export default CodeEnforcementForm
